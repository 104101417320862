@import './variables';

.backButton {
	z-index: 9;
	box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
		0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
	background: #fff !important;
	position: fixed !important;
	bottom: 3vh;
	left: 3vw;
}

// styles input tel for contact section
.telInput-base {
	margin: 1.5rem 0.6rem 0.6rem;
	font: inherit;
}

.telInput-base-before {
	content: 'Teléfono móvil*';
	font-size: 12px;
	position: absolute;
	top: -1.2rem;
}

.telInput-flag {
	height: 56px;
	cursor: pointer !important;
}

.telInput-selectFlag {
	width: 56px;
	height: 54px;
	padding: 18.5px 14px;
	border: none;
	background: #fff !important;
}

.teInput-formControl {
	color: $grayFont;
	font-size: 16px !important;
	padding-left: 62px !important;
	height: 56px !important;
	border-radius: 4px !important;
	box-shadow: 0 1px 2px #fff inset !important;
	&:focus {
		outline: transparent none 0 !important;
	}
}

.telInputFull {
	@extend .telInput-base;
	width: calc(100% - 1.2rem) !important;

	&::before {
		@extend .telInput-base-before;
		color: $lightGrayText;
	}

	&.noRequired {
		&::before {
			content: 'Telefono móvil' !important;
		}
	}

	.flag-dropdown {
		@extend .telInput-flag;
		.selected-flag {
			@extend .telInput-selectFlag;
			border-right: 1px $lightGrayAsistensi solid;
			&:focus {
				border: 2px $lightGrayAsistensi solid;
			}
		}
	}

	.form-control {
		@extend .teInput-formControl;
		border-color: $lightGrayAsistensi !important;
	}

	&:focus {
		border: 2px $lightGrayAsistensi solid;
		outline: transparent none 0 !important;
	}
}

.telInput {
	@extend .telInput-base;
	width: calc((100% / 3) - 1.2rem) !important;

	&::before {
		@extend .telInput-base-before;
		color: $lightGrayText;
	}

	&.noRequired {
		&::before {
			content: 'Teléfono móvil' !important;
		}
	}

	.flag-dropdown {
		@extend .telInput-flag;
		.selected-flag {
			@extend .telInput-selectFlag;
			border-right: 1px $lightGrayAsistensi solid;
			&:focus {
				border: 2px $lightGrayAsistensi solid;
			}
		}
	}

	.form-control {
		@extend .teInput-formControl;
		border-color: $lightGrayAsistensi !important;
	}

	&:focus {
		border: 2px $lightGrayAsistensi solid;
		outline: transparent none 0 !important;
	}
}

.telInput.otherLabel,
.telInputError.otherLabel {
	&::before {
		content: 'Otro teléfono' !important;
	}
}

.telInputDisabled {
	@extend .telInput-base;
	width: calc((100% / 3) - 1.2rem) !important;

	&::before {
		@extend .telInput-base-before;
		color: $lightGrayText;
	}

	.flag-dropdown {
		@extend .telInput-flag;
		.selected-flag {
			@extend .telInput-selectFlag;
			border-right: 1px $lightGrayAsistensi solid;
			&:focus {
				border: 2px $lightGrayAsistensi solid;
			}
		}
	}

	.form-control {
		@extend .teInput-formControl;
		color: #b0b2b4 !important;
		border-color: $lightGrayAsistensi !important;
	}

	&:focus {
		border: 2px $lightGrayAsistensi solid;
		outline: transparent none 0 !important;
	}
}

.telInputTwo {
	@extend .telInput-base;
	width: calc((100% / 2) - 1.2rem) !important;

	&::before {
		@extend .telInput-base-before;
		color: $lightGrayText;
	}

	.flag-dropdown {
		@extend .telInput-flag;
		.selected-flag {
			@extend .telInput-selectFlag;
			border-right: 1px $lightGrayAsistensi solid;
			&:focus {
				border: 2px $lightGrayAsistensi solid;
			}
		}
	}

	.form-control {
		@extend .teInput-formControl;
		border-color: $lightGrayAsistensi !important;
	}

	&:focus {
		border: 2px $lightGrayAsistensi solid;
		outline: transparent none 0 !important;
	}
}

.telInputErrorFull {
	@extend .telInput-base;
	width: calc(100% - 1.2rem) !important;

	&::before {
		@extend .telInput-base-before;
		color: $redError;
	}

	.flag-dropdown {
		@extend .telInput-flag;
		.selected-flag {
			@extend .telInput-selectFlag;
			border-right: 2px $redError solid;
			&:focus {
				border: 2px $redError solid;
			}
		}
	}

	.form-control {
		@extend .teInput-formControl;
		border-color: $redError !important;
	}

	&:focus {
		border: 2px $redError solid;
		outline: transparent none 0 !important;
	}
}

.telInputError {
	@extend .telInput-base;
	width: calc((100% / 3) - 1.2rem) !important;

	&::before {
		@extend .telInput-base-before;
		color: $redError;
	}

	.flag-dropdown {
		@extend .telInput-flag;
		.selected-flag {
			@extend .telInput-selectFlag;
			border-right: 1px $redError solid;
			&:focus {
				border: 2px $redError solid;
			}
		}
	}

	.form-control {
		@extend .teInput-formControl;
		border-color: $redError !important;
	}

	&:focus {
		border: 2px $redError solid;
		outline: transparent none 0 !important;
	}
}

.telInputTwoError {
	@extend .telInput-base;
	width: calc((100% / 2) - 1.2rem) !important;

	&::before {
		@extend .telInput-base-before;
		color: $redError;
	}

	.flag-dropdown {
		@extend .telInput-flag;
		.selected-flag {
			@extend .telInput-selectFlag;
			border-right: 1px $redError solid;
			&:focus {
				border: 2px $redError solid;
			}
		}
	}

	.form-control {
		@extend .teInput-formControl;
		border-color: $redError !important;
	}

	&:focus {
		border: 2px $redError solid;
		outline: transparent none 0 !important;
	}
}

.telInputNoLabel {
	@extend .telInput-base;
	width: 100% !important;

	.flag-dropdown {
		@extend .telInput-flag;
		.selected-flag {
			@extend .telInput-selectFlag;
			border-right: 1px $lightGrayAsistensi solid;
			&:focus {
				border: 2px $lightGrayAsistensi solid;
			}
		}
	}

	.form-control {
		@extend .teInput-formControl;
		border-color: $lightGrayAsistensi !important;
	}

	&:focus {
		border: 2px $lightGrayAsistensi solid;
		outline: transparent none 0 !important;
	}
}

.telInputNoLabelError {
	@extend .telInput-base;
	width: 100% !important;
	// width: calc((100% / 3) - 1.2rem) !important;

	.flag-dropdown {
		@extend .telInput-flag;
		.selected-flag {
			@extend .telInput-selectFlag;
			border-right: 1px $redError solid;
			&:focus {
				border: 2px $redError solid;
			}
		}
	}

	.form-control {
		@extend .teInput-formControl;
		border-color: $redError !important;
	}

	&:focus {
		border: 2px $redError solid;
		outline: transparent none 0 !important;
	}
}

@media #{$xs} {
	.telInput,
	.telInputError,
	.telInputTwo,
	.telInputDisabled,
	.telInputTwoError,
	.telInputNoLabel,
	.telInputNoLabelError {
		width: 100% !important;
	}
}

/* RESPONSIVE */
@media #{$sm} {
	.headline-form,
	.beneficiary-form,
	.payment-form,
	.voluntary-form {
		.textFieldFlagsTwo,
		.textFieldFlags,
		.textFieldFlagsSuccess,
		.telInput,
		.telInputError {
			width: calc((100% / 2) - 1.2rem) !important;
		}
	}
	.telInputDisabled,
	.telInputTwoError,
	.telInputNoLabel,
	.telInputNoLabelError {
		width: calc((100% / 2) - 1.2rem) !important;
	}
}
